import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/fabio2.jpg" // Tell webpack this JS file uses this image
import pal12 from "../images/palestrantes/nestor.jpg" // Tell webpack this JS file uses this image
import pal13 from "../images/palestrantes/pedromeireles.jpg" // Tell webpack this JS file uses this image
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import Footer from "../components/footer"

import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import logo3 from "../images/logos/georgia.png" // Tell webpack this JS file uses this image
import logo4 from "../images/logos/texastech.png" // Tell webpack this JS file uses this image
import logo5 from "../images/logos/westpoint.jpg" // Tell webpack this JS file uses this image
import logo6 from "../images/logos/atm.png" // Tell webpack this JS file uses this image

import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image
import foto6 from "../images/fotoscursos/foto22.jpg" // Tell webpack this JS file uses this image
import foto7 from "../images/fotoscursos/foto23.jpg" // Tell webpack this JS file uses this image
import foto8 from "../images/fotoscursos/foto24.jpg" // Tell webpack this JS file uses this image
import fotomasterclass from "../images/imagem-masterclass.jpg" // Tell webpack this JS file uses this image


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';
import UseAnimations from 'react-useanimations';
var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
            <div className="texto inter-bold">
             <span className="opacity"> Ecografia e palpação retal</span> <br></br>
              em bovinos<br></br>
            </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal7} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                     Rui d´Orey Branco
                      </div>
                    
                    <div className="titulo inter-light">
                     Consultor de Bovinos de Leite
                      </div>
                      </div>
                    
                  </div>
                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal13} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Pedro Meireles
                      </div>
                    
                    <div className="titulo inter-light">
                     Consultor 
                      </div>
                      </div>
                    
                  </div>

            
              
                </div>
                <div className="col-right inter-regular">
                    <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Tomar, Portugal
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                        08, 09 e 10 de Outubro de 2021
                      </div>
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                     Português
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                      465€ + IVA
                      </div>
                    
                    </div>
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/ecografia-palpacao.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                <div className="form">
                <a href="https://forms.gle/4NtivzENvRW4xv7y8" target="_blank" >
                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
                </div>
              </div>
            </div>
          </div>
        
      </div>
    </div>

    <div id="section-2-masterclass">
      <div className="wrapper-fixed">
      <img src={fotomasterclass} alt="" />
        </div>
      <div className="wrapper">
        <div className="wrapper-col-1">
          <div className="wrapper-text">
            <div className="subtitle">
              Descrição
              </div>
            <div className="title inter-black">
              Sobre o Curso
              </div>
              <div className="desc inter-light">
              Curso especificio para médicos veterinários e estudantes de medicina veterinária que pretendam aprofundar os seus conhecimentos em reprodução e desenvolver competências de palpação retal e iniciar a prática de ecografia transrectal para exame ginecológico em bovinos. O objectivo desde curso é dotar o formando dos princípios básicos destas técnicas bem como da melhor decisão na manipulação do ciclo estrico. Será também ministrado uma revisão da fisiologia reprodutiva por forma a consolidar os conceitos mais actuais em reprodução bovina. Durante a formação o participante vai dispor de um ecografo para uso próprio e treino com acompanhamento do formador.
                </div>
                <div className="logos">
                  
                <img src={logo5} alt="" />
                <img src={logo6} alt="" />

                  </div>
            </div>
          </div>
          <div className="wrapper-col-2">
          </div>
      </div>
    

    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
          <div className="text inter-regular">
          Os cursos intensivos FarmIN têm um porgrama detalhado que possibilita ao participante observar as diversas áreas abrangidas pelo curso, os diferentes módulos e a atividade dos palestrantes ao longo do dia. 
          
            </div>
      </div>
      <div className="wrapper-program">





      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                        <Accordion.Header>
                              
                            + Dia 1 - 12/11/2021 - Salão/Tomar
                            </Accordion.Header>

                            <Accordion.Body>
                               
                                <div class="modulo">
                                <p><span className="date">08:30 - 10:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Introdução ao curso palpação e ecografia reprodutiva breves nocoes históricas  </span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">10:00 - 10:30</span> | <span className="mod">Coffee-Break</span> </p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">10:30 - 13:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Fisiologia Reprodutiva e manipulação do ciclo estrico em bovinos   </span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:15</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:15 - 15:45</span> | <span className="mod">Módulo 3</span> - <span class="txt">Introdução à Gestão no controlo da Fertilidade </span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">15:45 - 16:00</span> | <span className="mod">Coffee-Break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:00 - 17:00</span> | <span className="mod">Módulo 4</span> - <span class="txt">Introducao a Gestão da Fertilidade na Produção de Bovinos </span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">17:00 - 18:30</span> | <span className="mod">Módulo 5</span> - <span class="txt">Treino de palpação em modelos com peças anatómicas (hands-on) </span></p>
                                </div>
          
                    

                                
                                </Accordion.Body>

                                </Accordion.Item>
                        

                                <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            +   Dia 2 - 13/11/2021 - Exploração Zona Centro / Prática
                            </Accordion.Header>

                            <Accordion.Body>
                                <div class="modulo">
                                <p><span className="date">08:30 - 10:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Iniciação à palpacão rectal em vacas - Parte I</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">10:00 - 10:30</span> | <span className="mod">Coffee-Break</span> </p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">10:30 - 13:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Iniciação à palpacão rectal em vacas - Parte II </span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:15</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:15 - 16:00</span> | <span className="mod">Módulo 3</span> - <span class="txt">Iniciação à prática ecografica reprodutiva em vacas - Parte I</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:00 - 16:15</span> | <span className="mod">Coffee-Break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:15 - 18:30</span> | <span className="mod">Módulo 4</span> - <span class="txt">Iniciação à prática ecografica reprodutiva em vacas - Parte II</span></p>
                                </div>
          
                                </Accordion.Body>
                                </Accordion.Item>

                                
                           
                      
                                <Accordion.Item eventKey="2">
                                <Accordion.Header>
                            
                            +  Dia 3 - 14/11/2021 - Exploração Zona Centro / Prática
                            </Accordion.Header>

                            <Accordion.Body>
                                <div class="modulo">
                                <p><span className="date">08:30 - 13:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Prática de ecografia reprodutora em bovinos</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:00</span> | <span className="mod">Almoço</span> </p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:00 - 18:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Prática de ecografia reprodutora em bovinos </span></p>
                                </div>
 
                          
          
                    

                                
                               </Accordion.Body>
                               </Accordion.Item>
                          
                      

                    


             
                       
               
                    </Accordion>
   
        </div>
        <div className="wrapper-btns">
        <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/ecografia-palpacao.pdf" target="_blank" >
                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                
                <div className="form">
                <a href="https://forms.gle/4NtivzENvRW4xv7y8" target="_blank" >

                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
          </div>
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
            </div>
      </div>
      <div className="wrapper-speakers">
        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal7} alt="" />

              </div>
              <div className="nome">
              Rui d´Orey Branco
              </div>
              <div className="titulo">
                 Consultor de Bovinos de Leite
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Consultor Sénior- ConCertA, Consultoria, Certificação e Agropecuária

</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Professor Assistente na Universidade Lusófona
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Doutorado em Fisiologia Reprodutiva - Texas AM
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
                  Awarded with Ronnie Excellence in Teaching - TAMU ANSC

</p>
                  

                 
                  </div>
              </div>
            </div>
        </div>
        </div>
        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal13} alt="" />

              </div>
              <div className="nome">
              Pedro Meireles
              </div>
              <div className="titulo">
                 Consultor
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Médico Veterinário - SVA - Serviços Veterinário Associados

</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Consultor especialista em fertilidade e Gestão de Unidades de Produção Leiteira
</p>
                    
                  </div>
              </div>
            </div>
        </div>
        </div>

   
   
      </div>
     

    </div>

    <div id="section-fotos">
      <Slider {...settings}>
      <div>
        <img src={foto6} alt="" />
      </div>
      <div>
        <img src={foto7} alt="" />
      </div>
      <div>
        <img src={foto8} alt="" />
      </div>
      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>
      <div>
        <img src={foto6} alt="" />
      </div>
      <div>
        <img src={foto7} alt="" />
      </div>
      <div>
        <img src={foto8} alt="" />
      </div>
      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    {/* <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div> */}
    <Footer/>

  </Layout>
)

export default wqmatadouros
